import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import '../styles/login-register.css';

const LoginRegister: React.FC = () => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [isTokenPresent, setIsTokenPresent] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
    const [tokenVerified, setTokenVerified] = useState(false); // Flag for stopping re-verification

    const BACKEND_URL = 'https://pokemon-game-backend-production.up.railway.app';
    const API_KEY = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // Function to verify token
    const verifyToken = useCallback(async (token: string) => {
        try {
            const response = await axios.post(`${BACKEND_URL}/verify-reset-token`, { token }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                setIsTokenPresent(true);
                setTokenVerified(true); // Mark token as verified to stop re-verification
            } else {
                showNotification('Invalid or expired token.', 'error');
                navigate('/'); // Redirect to login if token is invalid/expired
            }
        } catch (error) {
            showNotification('Error verifying token.', 'error');
            navigate('/'); // Redirect on error
        }
    }, [navigate, API_KEY]);

    useEffect(() => {
        const loginStatus = localStorage.getItem('isLoggedIn');
        if (loginStatus === 'true') {
            window.location.href = '/menu';
        } else {
            setIsLoading(false);
        }

        // If the password was updated or token is already verified, we skip re-verification
        if (isPasswordUpdated || tokenVerified) {
            return;
        }

        // Check if there is a token in the URL (for resetting password)
        const tokenFromUrl = searchParams.get('token');
        if (tokenFromUrl && !isPasswordUpdated) {
            setToken(tokenFromUrl);
            verifyToken(tokenFromUrl);  // Verifies the token only once
        } else {
            navigate('/'); // If no token, redirect to login
        }
    }, [searchParams, navigate, verifyToken, isPasswordUpdated, tokenVerified]);

    const toggleForm = () => {
        setIsRegistering(!isRegistering);
        clearForm();
    };

    const toggleResetPassword = () => {
        setIsResettingPassword(!isResettingPassword);
        clearForm();
    };

    const clearForm = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setNewPassword('');
        setEmail('');
        setIsEmailSent(false);
        setIsPasswordUpdated(false);
    };

    const showNotification = (message: string, type: 'success' | 'error') => {
        const notification = document.createElement('div');
        notification.classList.add('notification', type);
        notification.textContent = message;
        document.body.appendChild(notification);

        setTimeout(() => {
            notification.classList.add('show');
        }, 100);

        setTimeout(() => {
            notification.classList.remove('show');
            setTimeout(() => {
                notification.remove();
            }, 300);
        }, 3000);
    };

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!username || !password) {
            showNotification('Username and Password are required!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/login`, { username, password }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                localStorage.setItem('isLoggedIn', 'true');
                clearForm();
                window.location.href = '/menu';
            } else {
                showNotification('Login failed: Invalid username or password', 'error');
            }
        } catch (error) {
            showNotification('Error during login. Please try again.', 'error');
        }
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!username || !password || !email) {
            showNotification('Username, password, and email are required!', 'error');
            return;
        }

        if (!confirmPassword) {
            showNotification('Please confirm your password!', 'error');
            return;
        }

        if (password !== confirmPassword) {
            showNotification('Passwords do not match!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/register`, { username, password, email }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                showNotification('Registration successful', 'success');
                clearForm();
                setIsRegistering(false);
            } else {
                showNotification('Registration failed: User already exists', 'error');
            }
        } catch (error) {
            showNotification('Error during registration. Please try again.', 'error');
        }
    };

    const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!email) {
            showNotification('Email is required!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/reset-password`, { email }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                showNotification(`Password reset email sent to ${email}!`, 'success');
                setIsEmailSent(true);
            } else {
                showNotification('Reset failed: Email not found', 'error');
            }
        } catch (error) {
            showNotification('Error during password reset. Please try again.', 'error');
        }
    };

    const handleUpdatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!newPassword || !confirmPassword) {
            showNotification('Both password fields are required!', 'error');
            return;
        }

        if (newPassword !== confirmPassword) {
            showNotification('Passwords do not match!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/update-password`, { token, newPassword }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                showNotification('Password updated successfully!', 'success');
                setIsPasswordUpdated(true);  // Mark password as updated
                clearForm();
                // Instruction to refresh manually added here
            } else {
                showNotification('Error updating password. Please try again.', 'error');
            }
        } catch (error) {
            showNotification('Error during password update. Please try again.', 'error');
        }
    };

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="login-register-screen">
            <div className="login-register-container">
                <div className="login-register-box">
                    <h2>{isTokenPresent ? 'Update Password' : isResettingPassword ? 'Reset Password' : isRegistering ? 'Register' : 'Login'}</h2>

                    {isPasswordUpdated ? (
                        <div className="email-confirmation">
                            <p>Your password has been updated successfully!</p>
                        </div>
                    ) : isTokenPresent ? (
                        <form onSubmit={handleUpdatePassword} noValidate>
                            <input
                                type="password"
                                name="new-password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                            <input
                                type="password"
                                name="confirm-password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                                <div className="instructions">
                                    <p className="password-instruction">
                                        After changing your password, refresh the page to return to the login screen.
                                    </p>
                                    <p className="password-instruction">
                                        If you see a notification about the token not verifying or already being verified,
                                        don't worry! That's just the system allowing your account to use a new token
                                        for any future password changes.
                                    </p>
                                </div>
                            <div className="login-register-buttons">
                                <button type="submit">Update Password</button>
                            </div>
                        </form>
                    ) : isEmailSent ? (
                        <div className="email-confirmation">
                            <p>A reset link has been sent to your requested email. </p>
                            <p>Please check your inbox for further instructions.</p>
                            <button type="button" onClick={toggleResetPassword}>Back to Login</button>
                        </div>
                    ) : isResettingPassword ? (
                        <form onSubmit={handleResetPassword} noValidate>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                            <div className="login-register-buttons">
                                <button type="submit">Send Reset Link</button>
                                <button type="button" onClick={toggleResetPassword}>Back to Login</button>
                            </div>
                        </form>
                    ) : isRegistering ? (
                        <form onSubmit={handleRegister} noValidate>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="username"
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                            <input
                                type="password"
                                name="confirm-password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                            <div className="age-confirmation">
                                By registering, you confirm you are 13+ years of age or older.
                            </div>
                            <div className="login-register-buttons">
                                <button type="submit">Register</button>
                                <button type="button" onClick={toggleForm}>Back to Login</button>
                            </div>
                        </form>
                    ) : (
                        <form onSubmit={handleLogin} noValidate>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="username"
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                            <div className="forgot-password">
                                <button type="button" onClick={toggleResetPassword} className="link-button">
                                    Forgot your Password?
                                </button>
                            </div>
                            <div className="login-register-buttons">
                                <button type="submit">Login</button>
                                <button type="button" onClick={toggleForm}>Register</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginRegister;
