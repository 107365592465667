import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainMenu from './components/MainMenu';
import LoginRegister from './components/LoginRegister';
import './styles/main.css';  // Global CSS

const App: React.FC = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginRegister />} />
                <Route path="/menu" element={isLoggedIn ? <MainMenu /> : <Navigate to="/" />} />
                <Route path="/reset-password" element={<LoginRegister />} />
            </Routes>
        </Router>
    );
};

export default App;
